<template>
  <div class="ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="gift-card">
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <div class="tab-items">
        <div class="tab-item mobile-gift-card display">
          <div class="mobile-gift-card-info">
            <h2 class="scroll-active">다비치안경<br />모바일상품권 출시!</h2>
            <p class="scroll-active scroll-delay-1">
              모바일 상품권으로 눈 건강을 선물하세요!
            </p>
            <div class="scroll-active scroll-delay-2">
              <img src="https://img.davich.com/image/sub/support/img-support-phone.png" alt="모바일 상품권" />
            </div>
          </div>
          <div class="mobile-gift-card-link ly-cont">
            <h3 class="scroll-fade-in scroll-delay-2">구매하러 가기</h3>
            <div class="site-link">
              <div class="site-link-items scroll-fade-in scroll-delay-3">
                <a
                  class="site-link-item"
                  href="https://search.11st.co.kr/Search.tmall?kwd=%25EB%258B%25A4%25EB%25B9%2584%25EC%25B9%2598%2520%25EC%2583%2581%25ED%2592%2588%25EA%25B6%258C"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/11st.png" alt=""
                  /></em>
                  <span>11번가</span>
                </a>
                <a
                  class="site-link-item"
                  href=" http://browse.gmarket.co.kr/search?keyword=%EB%8B%A4%EB%B9%84%EC%B9%98%EC%95%88%EA%B2%BD%20%EC%83%81%ED%92%88%EA%B6%8C"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/gmarket.png" alt="지마켓 로고"
                  /></em>
                  <span>지마켓</span>
                </a>
                <a
                  class="site-link-item"
                  href="http://browse.auction.co.kr/search?keyword=%EB%8B%A4%EB%B9%84%EC%B9%98%EC%95%88%EA%B2%BD%20%EC%83%81%ED%92%88%EA%B6%8C"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/auction.png" alt="auction logo"
                  /></em>
                  <span>옥션</span>
                </a>
                <a
                  class="site-link-item"
                  href="https://smartstore.naver.com/zlgoon/search?q=%EB%8B%A4%EB%B9%84%EC%B9%98"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img
                      src="https://img.davich.com/image/sub/support/shopping/navershopping.png"
                      alt="네이버 쇼핑 로고"
                  /></em>
                  <span>네이버 쇼핑</span>
                </a>
                <a
                  class="site-link-item"
                  href="https://gift.kakao.com/brand/17709"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/kakao_talk.png" alt="kakaotalk_present"
                  /></em>
                  <span>카카오톡 선물하기</span>
                </a>
                <!--<a
                  class="site-link-item"
                  href="https://trip.coupang.com/tp/products/30000000540103?q=%EB%8B%A4%EB%B9%84%EC%B9%98%EC%95%88%EA%B2%BD%20%EC%83%81%ED%92%88%EA%B6%8C"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/coupang.png" alt="쿠팡 로고"
                  /></em>
                  <span>쿠팡</span>
                </a>
                <a
                  class="site-link-item"
                  href="https://front.wemakeprice.com/product/1612642149?search_keyword=%25EB%258B%25A4%25EB%25B9%2584%25EC%25B9%2598%25EC%2595%2588%25EA%25B2%25BD&_service=5&_no=1"
                  target="_blank"
                >
                  <em class="img-wrap">
                    <img src="https://img.davich.com/image/sub/support/shopping/wemap.png" alt="위메프 로고"
                  /></em>
                  <span>위메프</span>
                </a> -->
              </div>
              <div class="mobile-notice scroll-fade-in scroll-delay-2">
                <p>꼭 확인해주세요!</p>
                <ul>
                  <li>- 본 쿠폰은 다비치 전 매장에서 사용 가능합니다. (일부 매장 제외)</li>
                  <li>- 일부 품목 제외 (수입 콘택트렌즈, 용품, 수입 안경테 외)</li>
                  <li>- 결제 시 모바일쿠폰을 제시해 주시면 됩니다.</li>
                  <li>- 분할 사용이 가능하며, 잔액은 자동으로 저장됩니다.</li>
                  <li>- 매장에서 현금으로 교환 및 잔액 환급 불가합니다.</li>
                  <li>- 금액 초과 시 다른 결제 수단과 함께 결제 가능합니다.</li>
                  <li>- 타 쿠폰 중복 사용이나 포인트 적립 및 제휴카드 할인은 브랜드사 정책에 따릅니다.</li>
                  <li>- 프로모션용으로 발송된 쿠폰은 유효기간 연장 불가합니다.</li>
                  <li>- 이용 및 환불 문의는 즐거운 고객센터(1661-8191)로 연락 주시기 바랍니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 모바일 상품권 Tab -->
        <div class="tab-item paper-gift-card ly-cont">
          <h2 class="hidden">상품권 구매신청</h2>
          <div class="gift-card-application scroll-fade-in">
            <p class="application-desc">
              전국 다비치안경체인 어디서나 현금처럼 사용 가능한 다비치 상품권<br />
              상품권 구매를 원하시면, 무통장 입금 후 신청서를 작성해주세요.
            </p>
            <div class="application-procedure">
              <p>
                <span>01</span>
                <em
                  >무통장입금 및<br />
                  신청서 작성</em
                >
              </p>
              <p>
                <span>02</span>
                <em
                  >입금 및<br />
                  신청서 확인</em
                >
              </p>
              <p>
                <span>03</span>
                <em
                  >우편 발송
                  <span
                    >오전중 입금 확인시<br />
                    당일발송</span
                  >
                </em>
              </p>
            </div>
            <div class="paper-gift-img">
              <img
                class="paper-gift-img"
                src="https://img.davich.com/image/sub/support/img-paper-gift.png"
                alt="img paper-gift"
              />
            </div>
          </div>
          <div id="ly-form" class="ly-form application-form scroll-fade-in">
            <div class="ly-form-cont">
              <h3>신청서 내용</h3>
              <div class="form-cont">
                <p class="name-form">
                  <label for="name">성함<span>&#42;</span></label>
                  <input id="name" type="text" placeholder="성함을 입력해주세요." v-model="name" maxlength="20" />
                </p>
                <p class="email-form">
                  <label for="email">이메일<span>&#42;</span></label>
                  <input
                    id="email"
                    type="email"
                    placeholder="이메일 주소를 입력해주세요."
                    v-model="tempEmail"
                    @blur="sumDomain"
                    required
                  />
                  <span class="">&#64;</span>
                  <label for="input-domain" class="hidden">이메일 도메인 주소창</label>
                  <input id="input-domain" type="text" v-model="inputDomain" @blur="sumDomain" />
                  <label for="select-domain" class="hidden">이메일 도메인 주소 선택</label>
                  <select id="select-domain" v-model="domain" @change="sumDomain">
                    <option :value="0">직접입력</option>
                    <option :value="'naver.com'">naver.com</option>
                    <option :value="'hanmail.net'">hanmail.net</option>
                    <option :value="'gmail.com'">gmail.com</option>
                    <option :value="'daum.net'">daum.net</option>
                    <option :value="'nate.com'">nate.com</option>
                  </select>
                </p>
                <p class="number-form">
                  <label for="phonenumber">휴대폰<span>&#42;</span></label>
                  <span>
                    <!--01 연락처 입력부분-->
                    <span class="certifi-num-wrap">
                      <input
                        id="phonenumber"
                        type="text"
                        placeholder="'-'제외하고 숫자만입력"
                        v-model="mobile_no"
                        maxlength="11"
                      />
                      <!--참고_인증번호 받기 버튼 활성화 시 클래스 active 추가-->
                      <!--버튼(인증번호 받기 전)-->
                      <button id="get-authnum-btn" class="certifi-num-btn" @click="certifyMobile">인증번호 받기</button>
                      <!--버튼(인증번호 받은 후)-->
                      <!-- <button class="certifi-num-btn reissuance">재발급</button> -->
                      <!--연락처 올바르지 않을 시 나타나는 문구-->
                      <span class="caution hidden">올바른 휴대폰 번호를 입력해주세요.</span>
                    </span>
                    <!--02 인증번호 입력부분-->
                    <span class="certifi-num-wrap">
                      <!-- <label for="certification" class="">인증번호 입력</label> -->
                      <input
                        class="hidden"
                        id="certifi-input"
                        type="text"
                        placeholder="인증번호를 입력해주세요."
                        v-model="authNumber"
                        maxlength="6"
                      />
                      <!--문구(인증번호 받은 후)-->
                      <span class="caution hidden" id="timer"
                        >인증번호가 발송되었습니다. <em> 유효시간 {{ timeStr }}초</em></span
                      >
                      <!--문구(인증번호 틀린 후)-->
                      <span class="caution hidden">인증번호가 일치하지 않습니다. <em> 유효시간 : 3:00초</em></span>
                      <!--문구(인증번호 유효시간 초과 시)-->
                      <span class="caution hidden">인증번호를 재발급 해주세요. 유효시간 초과</span>
                      <!--문구(인증번호 인증 완료 후)-->
                      <span class="caution confirm hidden">인증이 완료되었습니다.</span>
                      <!--참고_인증하기 버튼 활성화 시 클래스 active 추가-->
                      <button class="certifi-num-btn hidden" id="certifi-btn" @click="confirmMobile">
                        인증번호 확인
                      </button>
                    </span>
                  </span>
                </p>

                <p class="address-form">
                  <label for="address">주소<span>&#42;</span></label>
                  <input id="address" type="text" v-model="address1" />
                  <button class="address-search-btn" @click="getAddress()">검색</button>
                </p>
                <p>
                  <label for="address-spec">상세<span>&#42;</span></label>
                  <input id="address-spec" type="text" placeholder="나머지 주소를 입력해주세요." v-model="address2" />
                </p>
                <p class="reason-form">
                  <label>기타사항<span>&#42;</span></label>
                  <textarea
                    name="reason"
                    id="reason"
                    cols="30"
                    rows="10"
                    placeholder="기타 필요한 사항을 입력해주세요."
                    v-model="message"
                    maxlength="2500"
                  >
                  </textarea>
                </p>
                <div class="gift-card-sort">
                  <strong>상품권 종류<span>&#42;</span></strong>
                  <div class="gift-card-number">
                    <div class="number-item">
                      <label for="number1">100,000원권</label>
                      <div class="counting">
                        <button type="button" class="minus" v-on:click="minusCount(10)">-</button>
                        <input
                          id="number1"
                          type="number"
                          name="number"
                          min="0"
                          max="100"
                          value="0"
                          disabled
                          v-model="voucher10"
                        />
                        <button type="button" class="plus" v-on:click="plusCount(10)">+</button>
                      </div>
                    </div>

                    <div class="number-item">
                      <label for="number2">50,000원권</label>
                      <div class="counting">
                        <button type="button" class="minus" v-on:click="minusCount(5)">-</button>
                        <input
                          id="number2"
                          type="number"
                          name="number"
                          min="0"
                          max="100"
                          value="0"
                          disabled
                          v-model="voucher5"
                        />
                        <button type="button" class="plus" v-on:click="plusCount(5)">+</button>
                      </div>
                    </div>

                    <div class="number-item">
                      <label for="number3">10,000원권</label>
                      <div class="counting">
                        <button type="button" class="minus" v-on:click="minusCount(1)">-</button>
                        <input
                          id="number3"
                          type="number"
                          name="number"
                          min="0"
                          max="100"
                          value="0"
                          disabled
                          v-model="voucher1"
                        />
                        <button type="button" class="plus" v-on:click="plusCount(1)">+</button>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="deposit-info">
                  <strong>입금정보<span>&#42;</span></strong>
                  <em class="deposit-info-spec">
                    <span class="total-price">{{ getTotalPrice }}</span>
                    <span class="bank-info">IBK기업은행 148-104419-01-010 ㈜다비치안경체인</span>
                    <span class="price-notice"
                      >※ 다비치 상품권은 입금 확인 후 발송되며, 오전중 입금 확인시 당일 발송 됩니다.</span
                    >
                  </em>
                </p>
              </div>
            </div>
            <div class="ly-form-agree">
              <h3>개인정보 수집동의</h3>
              <div class="agree-cont">
                <div class="agree-txt" v-html="contents"></div>
                <div class="agree-chk">
                  <input id="agree-chk" type="checkbox" v-model="isAgree" />
                  <label for="agree-chk"
                    >수집하는 개인정보 항목, 수집/이용목적, 개인정보 보유기간에 동의합니다.<span>&#42;</span></label
                  >
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="https://img.davich.com/image/sub/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </div>
                <div class="notice-form">※ 문의 : 본사 회계팀 070-7433-0270</div>
              </div>
            </div>
            <button class="el-btn question-btn question-btn-left" @click="postFormData">신청하기</button>
          </div>
        </div>
        <!-- 지류 상품권 Tab -->
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import TabMenu from "../../components/TabMenu.vue";

import axios from "@/api/axios-auth";
import { mapState } from "vuex";

export default {
  components: {
    SubTitle,
    TabMenu,
  },
  data() {
    return {
      //sub title
      title: "상품권 구매",
      content: "전국 다비치안경체인 어디서나 사용 가능합니다.",
      //tabmenu
      tabTitle: ["모바일 상품권", "지류 상품권"],

      //formData
      name: "",
      email: "",
      domain: 0,
      mobile_no: "",
      address1: "",
      address2: "",
      message: "",
      voucher10: 0,
      voucher5: 0,
      voucher1: 0,
      total_price: 0,

      tempEmail: "",
      inputDomain: "",

      authnumber_id: 0,
      authNumber: null,

      isCertified: false,
      isAgree: false,

      timer: null,
      timeCounter: 180,
      timeStr: "03:00",
    };
  },

  computed: {
    ...mapState({
      contents: (state) => state.others.privacy[0].contents,
    }),

    getTotalPrice() {
      const self = this;
      let totalPrice = this.voucher10 * 100000 + this.voucher5 * 50000 + this.voucher1 * 10000;

      self.total_price = totalPrice;

      const result = totalPrice.toLocaleString("ko-KR");
      return result;
    },
  },

  watch: {
    name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.name = result));
    },

    tempEmail(value) {
      const self = this;

      const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, "").replace(/[@]/g, "");
      self.$nextTick(() => (self.tempEmail = result));
    },

    mobile_no(value) {
      const self = this;
      const btn = document.getElementById("get-authnum-btn");
      if (self.mobile_no.length == 11) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.mobile_no = result));
    },

    authNumber(value) {
      const self = this;
      const btn = document.getElementById("certifi-btn");
      if (self.authNumber.length == 6) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }
      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.authNumber = result));
    },

    domain() {
      const self = this;
      const domainInput = document.getElementById("input-domain");

      if (self.domain == "0") {
        domainInput.removeAttribute("disabled");
        domainInput.setAttribute("type", "text");
      } else {
        domainInput.setAttribute("disabled", true);
        domainInput.setAttribute("type", "hidden");
        self.inputDomain = self.domain;
      }
    },
  },

  methods: {
    certifyMobile() {
      if (this.mobile_no == "") {
        alert("휴대폰 번호를 입력하세요");
        return 0;
      }
      const getAuthnumBtn = document.getElementById("get-authnum-btn");
      const certifyInput = document.getElementById("certifi-input");
      const certifyBtn = document.getElementById("certifi-btn");
      const countTimer = document.getElementById("timer");
      getAuthnumBtn.classList.add("active");
      certifyInput.classList.remove("hidden");
      certifyBtn.classList.remove("hidden");
      countTimer.classList.remove("hidden");
      this.setTimer();

      if (getAuthnumBtn.innerText != "재발급") {
        getAuthnumBtn.innerText = "재발급";
      } else {
        alert("인증번호가 재발송되었습니다.");
        //타이머 초기화
        this.timerStop(this.timer);
        this.timeCounter = 180;
        this.timeStr = "03:00";
        this.timer = null;
        this.timeStart();
      }

      axios
        .post("/message/authnumber", {
          board_name: "customer_voice",
          mobile_no: this.mobile_no,
        })
        .then((res) => {
          this.authnumber_id = res.data.id;
        })
        .catch((err) => console.log(err));
    },

    confirmMobile() {
      axios
        .post("/message/authnumber/check", {
          mobile_no: this.mobile_no,
          authnumber_id: this.authnumber_id,
          input_data: this.authNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            alert("인증이 완료되었습니다.");
            this.isCertified = true;
            this.timerStop(this.timer);

            document.getElementById("get-authnum-btn").classList.add("hidden");
            document.getElementById("certifi-input").classList.add("hidden");
            document.getElementById("certifi-btn").classList.add("hidden");
            document.getElementById("timer").classList.add("hidden");

            document.getElementById("phonenumber").setAttribute("disabled", true);
            document.getElementById("get-authnum-btn").setAttribute("disabled", true);

            const confirm = document.getElementById("auth-confirm");
            confirm.classList.remove("hidden");
          }
        })
        .catch((err) => {
          switch (err.response.data.error_code) {
            case 1:
              alert("유효시간이 초과되었습니다.");
              break;
            case 2:
              alert("입력하신 번호가 일치하지 않습니다.");
              break;
          }
        });
    },

    getAddress() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let fullAddress = "";
          let postCode = data.zonecode;

          if (this.address2 !== "") {
            this.address2 = "";
          }

          if (data.userSelectedType === "R") {
            fullAddress = data.roadAddress;
          } else {
            fullAddress = data.jibunAddress;
          }

          fullAddress += ` (우: ${postCode})`;
          this.address1 = fullAddress;
        },
      }).open();
    },

    postFormData() {
      const self = this;

      //예외처리
      if (
        self.name.length == 0 ||
        self.email.length == 0 ||
        self.mobile_no.length == 0 ||
        self.address1.length == 0 ||
        self.message.length == 0 ||
        self.total_price == 0
      ) {
        alert("필수항목을 기입해 주세요");
        return 1;
      }

      if (self.isAgree == false) {
        alert("개인정보 수집동의에 체크해주세요.");
        return 1;
      }
      if (self.isCertified == false) {
        alert("휴대폰 인증을 완료해주세요.");
        return 1;
      }

      if (self.isAgree == true && self.isCertified == true) {
        // this.$store.dispatch("partnership/POST_OPEN_INQUIRE", dataToPost);
        axios
          .post(
            `/support/giftcardorder`,
            {
              name: self.name,
              email: self.email,
              mobile_no: self.mobile_no,
              address1: self.address1,
              address2: self.address2,
              message: self.message,
              voucher10: self.voucher10,
              voucher5: self.voucher5,
              voucher1: self.voucher1,
              total_price: self.total_price,
            },
            {
              headers: {
                "Content-Type": `application/json`,
              },
            }
          )
          .then(() => {
            alert("상품권 신청이 완료되었습니다. 감사합니다.");
            this.$router.replace({
              path: "/support/giftCard",
              query: { index: 1 },
            });

            //입력폼 초기화
            self.name = "";
            self.email = "";
            self.tempEmail = "";
            self.domain = 0;
            self.inputDomain = "";
            self.mobile_no = "";
            self.address1 = "";
            self.address2 = "";
            self.message = "";
            self.voucher10 = 0;
            self.voucher5 = 0;
            self.voucher1 = 0;
            self.total_price = 0;

            self.authnumber_id = 0;
            self.authNumber = null;
            self.isCertified = false;
            self.isAgree = false;

            self.timer = null;
            self.timeCounter = 180;
            self.timeStr = "03:00";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    plusCount(voucher) {
      switch (voucher) {
        case 10:
          this.voucher10++;
          break;
        case 5:
          this.voucher5++;
          break;
        case 1:
          this.voucher1++;
          break;
      }
    },

    minusCount(voucher) {
      switch (voucher) {
        case 10:
          if (this.voucher10 > 0) {
            this.voucher10--;
          }
          break;
        case 5:
          if (this.voucher5 > 0) {
            this.voucher5--;
          }
          break;
        case 1:
          if (this.voucher1 > 0) {
            this.voucher1--;
          }
          break;
      }
    },

    //타이머
    timeStart() {
      this.TimeCounter = 180;
      let interval = setInterval(() => {
        this.timeCounter--;
        this.timeStr = this.prettyTime();
        if (this.timeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop(timer) {
      clearInterval(timer);
      this.timeCounter = 0;
    },
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0");
    },
    setTimer() {
      if (this.timer != null) {
        this.timerStop(this.timer);
        this.timer = null;
      }
      this.timer = this.timeStart();
    },

    //직접입력 이메일 합치기
    sumDomain() {
      const self = this;
      if (self.domain == "0") {
        let fullEmail = self.tempEmail + "@" + self.inputDomain;
        self.email = fullEmail;
      } else {
        self.email = self.tempEmail + "@" + self.domain;
      }
    },
  },

  mounted() {
    // 신청하기 버튼 클릭후 페이지 이동
    if (this.$route.query.index == "1") {
      const tabBtn = document.querySelectorAll(".tab-btn");
      const tabCont = document.querySelectorAll(".tab-item");

      tabBtn[0].classList.remove("active");
      tabBtn[1].classList.add("active");

      tabCont[0].classList.remove("display");
      tabCont[1].classList.add("display");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-support-bg-02.png");
}
.gift-card {
  //mobile gift card(모바일 상품권 Tab)
  .mobile-gift-card {
    //info
    .mobile-gift-card-info {
      text-align: center;
      height: 1077px;
      background-color: #f0f8ff;
      h2 {
        font-size: 45px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: -1.35px;
        padding-top: 70px;
        margin-bottom: 40px;
      }
      p {
        font-size: 18px;
        line-height: 35px;
        letter-spacing: -0.54px;
        margin-bottom: 70px;
      }
    }
    //link
    .mobile-gift-card-link {
      padding: 368px 0 150px 0;
      h3 {
        @include font-h-35;
        margin-bottom: 27px;
      }
      .site-link {
        .site-link-items {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 110px;
          .site-link-item {
            border: 1px solid #ccc;
            width: 320px;
            height: 170px;
            padding: 0 30.5px;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-right: 40px;
            margin-bottom: 40px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            .img-wrap {
              width: 100%;
              height: 110px;
              @include box-center-flex;
            }
            span {
              width: 100%;
              @include font-main;
              border-top: 1px solid #ccc;
              padding-top: 14.5px;
            }
          }
        }
        .mobile-notice {
          padding: 50px;
          border: 1px solid #ccc;
          p {
            @include font-h-25;
          }
          li {
            @include font-main;
          }
        }
      }
    }
  }
  //paper gift card(지류 상품권 Tab)
  .paper-gift-card {
    padding-top: 56px;
    padding-bottom: 200px;
    .gift-card-application {
      margin-bottom: 70px;
      padding-top: 60px;
      background-color: #f0f8ff;
      height: 850px;
      text-align: center;
      .application-desc {
        @include font-main;
        line-height: 35px;
        margin-bottom: 58px;
      }
      .application-procedure {
        color: $color-blue;
        display: flex;
        position: relative;
        justify-content: center;
        &:before {
          content: "";
          position: absolute;
          @include box-center;
          z-index: 0;
          width: 500px;
          height: 0;
          border: 1px dashed $color-blue;
        }
        p {
          position: relative;
          margin: 0 75px;
          background-color: #f0f8ff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid $color-blue;
          border-radius: 50%;
          width: 200px;
          height: 200px;
          > span {
            margin-bottom: 16px;
            font-size: 30px;
            font-family: $en-font;
            font-weight: 900;
            letter-spacing: -0.9px;
          }
          em {
            font-size: 20px;
            color: $color-blue;
            font-weight: bold;
            line-height: 30px;

            span {
              display: block;
              color: $color-black;
              font-weight: normal;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: -0.48px;
            }
          }
        }
      }
    }
    .paper-gift-img {
      img {
        width: 800px;
        text-align: center;
        z-index: 6;
        position: relative;
        top: -20px;
      }
    }

    /*신청 폼*/
    .application-form {
      //address
      margin-top: 250px;
      .address-form {
        position: relative;
        .address-search-btn {
          right: 0;
          position: absolute;
          bottom: 8px;
          width: 140px;
          height: 60px;
          color: $color-black;
          border: 1px solid $color-black;
          font-size: 16px;
        }
        & + p {
          label {
            opacity: 0;
          }
        }
      }
      //상품권 종류
      .gift-card-sort {
        input {
          text-align: center;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      }
      .gift-card-sort {
        padding: 26px 0 35px 0;
        .gift-card-number {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .number-item {
            display: flex;
            align-items: center;
            @include font-main;
            label {
              width: 150px;
              line-height: 40px;
            }
            .counting {
              display: flex;
              align-items: center;
              input {
                @include font-main;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                height: 40px;
                width: 40px;
                box-sizing: border-box;
              }
              button {
                width: 40px;
                height: 40px;
                border: 1px solid #ccc;
              }
            }
          }
        }
      }
      //입금정보
      .deposit-info,
      .gift-card-sort {
        display: flex;
        //제목
        strong {
          flex-shrink: 0;
          width: 200px;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: -0.66px;
          span {
            display: inline-block;
            margin-left: 4px;
            color: #00adef;
          }
        }
      }
      .deposit-info {
        .deposit-info-spec {
          > span {
            display: block;
          }
          .total-price {
            color: $color-blue;
            font-size: 35px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.86;
            letter-spacing: -1.05px;
            margin-bottom: 11px;
          }
          .bank-info,
          .price-notice {
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.76;
            letter-spacing: -0.51px;
          }
          .bank-info {
            color: $color-blue;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

//notice
.notice-form {
  margin-top: 52px;
  padding: 30px 20px;
  background-color: #f0f8ff;
  @include font-main;
}
</style>
