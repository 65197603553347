import { fetchCf, fetchNotice, fetchNews, fetchEvent, fetchMagazine } from "@/api/index";

export const actions = {
  FETCH_NEWS: function (context) {
    fetchNotice()
      .then((noticeRes) => {
        fetchNews()
          .then((newsRes) => {
            context.commit("SET_NEWS_BOARD", [noticeRes, newsRes]);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  },

  FETCH_CF: function (context) {
    fetchCf()
      .then((res) => {
        context.commit("SET_CF", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_EVENT(context) {
    fetchEvent()
      .then((res) => {
        context.commit("SET_EVENT", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_MAGAZINE(context) {
    fetchMagazine()
      .then((res) => {
        context.commit("SET_MAGAZINE", res.data);
      })
      .catch((err) => console.log(err));
  },
};
