import { render, staticRenderFns } from "./3_2_m_magazineDetail.vue?vue&type=template&id=6b137242&scoped=true&"
import script from "./3_2_m_magazineDetail.vue?vue&type=script&lang=js&"
export * from "./3_2_m_magazineDetail.vue?vue&type=script&lang=js&"
import style0 from "./3_2_m_magazineDetail.vue?vue&type=style&index=0&id=6b137242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b137242",
  null
  
)

export default component.exports