export const getters = {
  getNewsData: function (state) {
    return state.boardData;
  },

  getCfData: function (state) {
    return state.cfData;
  },

  getEventData(state) {
    return state.eventData;
  },
  
  getMagazineData(state) {
    return state.magazineData;
  },
};
