import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const state = {
  cfData: "",
  boardData: "",
  searchedData: "",
  searchedCf: "",
  eventData: "",
  magazineData: "",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
