<template>
    <div class="ly-w1400">
      <div class="ly-wrap">
        <div class="contribution-post" v-bind="contentToShow">
          <div
            class="post-img"
            v-for="(src, index) in contentToShow[0].imgSrc"
            :key="index"
          >
            <img :src="src" alt="post sample image" />
          </div>
          <p class="post-txt" v-html="contentToShow[0].contents"></p>
        </div>
      </div>
      <!-- 이전글 다음글 목록 -->
      <!-- <div class="next-prev-post">
        <p>
          <em
            ><img
              src="https://img.davich.com/image/sub/about/icon-arrow-up.png"
              alt="arrow top icon"
            />
            이전글</em
          >
          <router-link
            :to="`/whatsnew/davichNewsDetail/${prevData[0].id}`"
            class="prev-post"
            v-if="prevData.length != 0"
            >{{ prevData[0].title }}</router-link
          >
          <a class="prev-post" v-else> 이전 게시물이 없습니다. </a>
        </p>
        <p>
          <em
            ><img
              src="https://img.davich.com/image/sub/about/icon-arrow-down.png"
              alt="arrow top icon"
            />다음글</em
          >
          <router-link
            :to="`/whatsnew/davichNewsDetail/${nextData[0].id}`"
            class="next-post"
            v-if="nextData.length != 0"
            >{{ nextData[0].title }}</router-link
          >
          <a class="next-post" v-else> 다음 게시물이 없습니다. </a>
        </p>
      </div> -->
  
      <div class="post-list-btn">
        <router-link class="el-btn" to="/whatsnew/magazine"
          >목록보기</router-link
        >
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  
  export default {
    props: ["boardid"],
    data() {
      return {

      };
    },
    computed: {
      ...mapState("whatsnew", {
        magazineData: (state) => state.magazineData,
      }),
  
      contentToShow() {
        const self = this;
        let result = [];
        let map = [];

        if (this.magazineData && this.magazineData.length) {
          result = self.magazineData.filter((item) => self.boardid == item.id);

          map = result.flatMap(item => {
            return item.type.map((type, index) => {
              if (type === 'details') {
                return item.imgSrc[index];
              }
            }).filter(item => item !== undefined);
          });

          if (result.length > 0) {
            let clonedResult = JSON.parse(JSON.stringify(result));
            clonedResult[0].imgSrc = map;  // map 배열을 직접 할당
            return clonedResult;
          }
        }

        return result;
      },
      prevData() {
        const self = this;
  
        let result;
        // result = self.boardData.filter((item) => {
        //   let thisData = true;
        //   thisData = self.contentToShow[0].index - 1 == item.index;
        //   return thisData;
        // });
  
        return result;
      },
  
      nextData() {
        const self = this;
  
        let result;
        // result = self.boardData.filter((item) => {
        //   let thisData = true;
        //   thisData = self.contentToShow[0].index + 1 == item.index;
        //   return thisData;
        // });
  
        return result;
      },
    },
    created(){
      if (this.magazineData == '' || this.magazineData == null){
        this.$store.dispatch("whatsnew/FETCH_MAGAZINE");
      }
    },
    mounted() {
      // texteditor에 불러오는 이미지 사이즈 고정
      let img = document.querySelectorAll(".post-txt img");
      for (var i = 0; i < img.length; i++) {
        img[i].style.maxWidth = "100%";
        img[i].style.height = "auto";
      }
      let a = document.querySelectorAll('.post-txt a');
      if(a.length > 0){
        let imgpt = document.querySelectorAll('.post-img');
        for(let i=0;i<imgpt.length;i++){
          imgpt[i].append(a[i]);
          imgpt[i].childNodes[1].append(imgpt[i].childNodes[0]);
        }
      }
    },
    updated() {
      // texteditor에 불러오는 이미지 사이즈 고정
      let img = document.querySelectorAll(".post-txt img");
      for (var i = 0; i < img.length; i++) {
        img[i].style.maxWidth = "100%";
        img[i].style.height = "auto";
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "~@/assets/scss/helpers/variables";
  @import "~@/assets/scss/helpers/mixins";
  @import "~@/assets/scss/base/typhography";
  
  //본문 내용
  .contribution-post {
    margin: auto;
    padding: 75px 0;
    text-align: center;
    // .post-img {
    //   width: 700px;
    //   height: auto;
    //   img {
    //     width: 100%;
    //   }
    // }
    .post-txt {
      padding: 10px 0;
      @include font-main;
    }
  }
  
  //이전글 다음글 목록
  .next-prev-post {
    width: 700px;
    margin: auto;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    p {
      @include font-main;
      padding: 20px;
      display: flex;
      align-items: center;
      &:first-child {
        border-bottom: 1px solid #ccc;
      }
      em {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-right: 34px;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  
  //목록보기 버튼
  .post-list-btn {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }
  </style>
  