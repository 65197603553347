<template>
  <!-- eslint-disable -->
  <div>
    <header class="header">
      <div class="ly-header-inner header-inner">
        <h1>
          <router-link to="/"
            ><img class="header-logo" src="https://img.davich.com/image/main/logo-all.png" alt="davich logo"
          /></router-link>
        </h1>
        <nav>
          <ul class="gnb">
            <li class="main-menu">
              <router-link to="/about/greetings">ABOUT​</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-0">
                  <li>
                    <router-link to="/about/greetings">대표 인사말​</router-link>
                  </li>
                  <li>
                    <router-link to="/about/information">기업소개</router-link>
                  </li>
                  <li>
                    <router-link to="/about/identity">비주얼 아이덴티티​​</router-link>
                  </li>
                  <li>
                    <router-link to="/about/contribution">사회공헌​</router-link>
                  </li>
                  <li>
                    <router-link to="/about/location">오시는 길</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="main-menu">
              <router-link to="/products/glasses">PRODUCTS​</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-1">
                  <li>
                    <router-link to="/products/glasses">안경테 	&#38; 선글라스</router-link>
                  </li>
                  <li>
                    <router-link to="/products/glassLens">안경렌즈​</router-link>
                  </li>
                  <li>
                    <router-link to="/products/contactLens">콘택트렌즈</router-link>
                  </li>
                  <li>
                    <router-link to="/products/hearingAid">보청기​</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="main-menu">
              <router-link to="/partnership/franchise">PARTNERSHIP​</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-2">
                  <li>
                    <router-link to="/partnership/franchise">가맹점현황​</router-link>
                  </li>
                  <li>
                    <router-link to="/partnership/howto">가맹점 개설안내</router-link>
                  </li>
                  <li>
                    <router-link to="/partnership/inquiryFranchise">가맹점 개설문의​</router-link>
                  </li>
                  <li>
                    <router-link to="/partnership/inquiryPartnership">제휴문의</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="main-menu">
              <router-link to="/education/researchCenter">EDUCATION​</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-3">
                  <li>
                    <router-link to="/education/researchCenter">고객가치경영연구원​</router-link>
                  </li>
                  <li>
                    <router-link to="/education/officer">다비치 사관&middot;공채</router-link>
                  </li>
                  <li>
                    <router-link to="/education/recruit">사관&middot;공채&middot;코디네이터 모집</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="main-menu">
              <router-link to="/whatsnew/cf">WHAT'S NEW</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-4">
                  <li>
                    <router-link to="/whatsnew/cf">광고/홍보영상​</router-link>
                  </li>
                  <li>
                    <router-link to="/whatsnew/davichNews">다비치 소식</router-link>
                  </li>
                  <li>
                    <router-link to="/whatsnew/magazine">매거진</router-link>
                  </li>
                  <li>
                    <router-link to="/whatsnew/event">이벤트</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="main-menu">
              <router-link to="/support/ask">SUPPORT</router-link>
              <div class="sub-menu">
                <ul class="sub-menu-5">
                  <li>
                    <router-link to="/support/ask">고객의 소리​</router-link>
                  </li>
                  <li>
                    <router-link to="/support/faq">자주하는 질문​</router-link>
                  </li>
                  <li>
                    <router-link to="/support/giftCard">상품권 구매</router-link>
                  </li>
                  <li>
                    <router-link to="/support/findStore">매장찾기​</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        <div class="header-utils">
          <div class="header-search">
            <router-link to="/support/findStore">
              <span>매장찾기</span>
            </router-link>
          </div>
          <div class="header-market">
            <a class="el-btn-icon" href="https://www.davichmarket.com" target="_blank">
              <span>다비치 마켓</span>
              <img class="header-cart-icon" src="https://img.davich.com/image/main/icon-arrow.png" alt="cart icon" />
            </a>
          </div>
        </div>
      </div>
      <hr />
    </header>
  </div>
</template>
<script>
/*eslint-disable */

export default {
  data() {
    return {
      opacityList: [
        //opacity1로 헤더설정할 페이지의 route name을 입력

        //about
        // "greetings",
        // "information",
        "contribution",
        "contributionPostDetail",

        // //partnership
        // "inquiryFranchise",
        // "inquiryPartnership",

        // education
        "recruitDetail",

        // //whatnew
        // "cf",
        "davichNewsDetail",
        "magazineDetail",

        // //support
        // "giftCard",
        "findStore",

        // //privacy
        "privacyPolicy",
        "emailProtect",

        // notFoundPage
        "notFoundPage",
      ],
    };
  },
  watch: {
    //라우트 변경 감지하여 헤더 opacity 결정
    $route(to, from) {
      const header = document.querySelector("header");
      const opacityList = this.opacityList;

      if (opacityList.includes(to.name)) {
        console.log(to.name);
        header.classList.add("opacity1");
      } else {
        console.log(to.name);
        header.classList.remove("opacity1");
      }
    },
  },
  mounted() {
    var header = document.querySelector("header");
    var subMenuHeight = 0;
    var headerHeight = header.offsetHeight;
    var mainMenu = document.querySelectorAll(".main-menu");

    //마운트할 때 헤더 opacity 결정
    const opacityList = this.opacityList;
    if (opacityList.includes(this.$route.name)) {
      header.classList.add("opacity1");
    } else {
      header.classList.remove("opacity1");
    }

    //show submenu
    for (var i = 0; i < mainMenu.length; i++) {
      mainMenu[i].addEventListener("mouseover", function () {
        for (var z = 0; z < mainMenu.length; z++) {
          header.style.transition = 0.3 + "s";
          mainMenu[z].classList.remove("on");
        }
        this.classList.add("on");
        subMenuHeight = this.querySelector("ul").offsetHeight;
        header.style.height = headerHeight + subMenuHeight + "px";
      });

      mainMenu[i].addEventListener("focusin", function () {
        for (var z = 0; z < mainMenu.length; z++) {
          mainMenu[z].classList.remove("on");
        }
        // header.classList.add("opacity1");
        this.classList.add("on");
        subMenuHeight = this.querySelector("ul").offsetHeight;
        header.style.height = headerHeight + subMenuHeight + "px";
      });
      mainMenu[i].addEventListener("mouseout", function () {
        header.style.height = headerHeight + "px";
        this.classList.remove("on");
      });
      mainMenu[i].addEventListener("focusout", function () {
        // header.classList.remove("opacity1");
        // header.style.height = headerHeight + "px";
        // this.classList.remove("on");
      });
    }

    //scroll
    window.addEventListener("scroll", function () {
      if (window.scrollY !== 0) {
        header.classList.add("scroll");
      } else {
        header.classList.remove("scroll");
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.ly-header-inner {
  width: 1720px;
  margin: auto;
}

header {
  min-width: 1400px;
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999999999;
  &:hover,
  &.scroll,
  &.opacity1 {
    background-color: $color-white;
    border-bottom: 1px solid #ccc;
    .header-inner {
      h1 {
        a {
          img {
            left: 0;
          }
        }
      }
      nav {
        .gnb {
          .main-menu {
            a {
              color: $color-black;
            }
          }
        }
      }
      .header-utils {
        .header-market {
          background-color: $color-blue;
        }
        .header-search {
          background-color: $color-black;
        }
      }
    }
    hr {
      border: none;
      height: 1px;
      background-color: $color-black;
      opacity: 0.1;
      pointer-events: none;
    }
  }
  &.scroll {
    position: fixed;
  }
  .header-inner {
    width: 1400px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin-right: 135.8px;
      a {
        width: 166.2px;
        height: 40.1px;
        overflow: hidden;
        position: relative;
        img {
          position: absolute;
          height: 100%;
          top: 0;
          left: -182px;
        }
      }
    }
    nav {
      flex: 1;
      height: 100%;
      .gnb {
        display: flex;
        .main-menu {
          a {
            font-family: $en-font;
            font-weight: 500;
            display: block;
            padding: 28px 20px;
            color: white;
            font-size: 17px;
            line-height: 22px;
          }
          .sub-menu {
            position: absolute;
            display: none;
            height: 80px;
            width: 100vw;
            top: 80px;
            left: 0;
            background-color: $color-white;
          }
        }
        .main-menu.on {
          > a {
            color: $color-blue;
            border-bottom: 3px solid $color-blue;
          }
          .sub-menu {
            display: block;
            width: 100%;
            > ul {
              height: 100%;
              display: flex;
              align-items: center;
              position: absolute;
              top: 0;
              left: 50.5%;
              transform: translateX(-50%);
              width: 810px;
              &.sub-menu-0 {
                padding-left: 0px;
              }
              &.sub-menu-1 {
                padding-left: 0px;
              }
              &.sub-menu-2 {
                padding-left: 50px;
              }
              &.sub-menu-3 {
                padding-left: 208px;
              }
              &.sub-menu-4 {
                padding-left: 410px;
              }
              &.sub-menu-5 {
                padding-left: 305px;
              }
            }

            a {
              font-family: $ko-font;
              padding: 10px 15px;
              color: $color-black;
              &:hover {
                color: $color-blue;
              }
            }
          }
        }
      }
    }
    .header-utils {
      display: flex;
      align-items: center;
      line-height: 22px;
      > div {
        padding: 5px 15px;
        border-radius: 50px;
        a {
          font-size: 14px;
          display: flex;
          align-items: center;
          color: $color-white;
        }
        &:nth-of-type(1) {
          margin-right: 10px;
        }
      }
    }
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: white;
    border: none;
  }
}
</style>
