export const mutations = {
  SET_CF: function (state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};
      //유투브링크에서 유니크주소 추출
      let uniqueUrl = item.youtube_link.split("=")[1];

      obj.id = item.id;
      obj.url = item.youtube_link;
      obj.uniqueUrl = uniqueUrl;
      obj.imgurl = `https://img.youtube.com/vi/${uniqueUrl}/sddefault.jpg`;

      switch (item.type) {
        case 1:
          obj.type = "TV CF";
          break;
        case 2:
          obj.type = "DIGITAL CF";
          break;
      }
      obj.title = item.title;
      obj.content = item.subtitle;
      obj.notice = item.notice_open;
      obj.date = item.created_dttm.split("T")[0];

      result.push(obj);
    }

    //id순으로 정렬
    let sorted;
    sorted = result.sort(function (a, b) {
      return b.id - a.id;
    });

    state.cfData = sorted;
  },

  SEARCH_CF: function (state, payload) {
    let boardData = payload[0];
    let searchText = payload[1];

    let result;
    result = boardData.filter((item) => {
      let filtered = true;
      filtered =
        item.title.includes(searchText) || item.content.includes(searchText);
      return filtered;
    });

    state.searchedCf = result;
  },

  SET_NEWS_BOARD: function (state, payload) {
    let noticeData = payload[0].data;
    let newsData = payload[1].data;

    let result = [];

    for (let item of noticeData) {
      let obj = {};
      obj.id = item.id;
      obj.class = "공지";
      obj.title = item.title;
      obj.contents = item.contents;
      obj.imgSrc = [];
      for (let attc of item.attachments) {
        obj.imgSrc.push(attc.download_url);
      }
      obj.created_dttm = item.created_dttm.split("T")[0];

      result.push(obj);
    }

    for (let item of newsData) {
      let obj = {};
      obj.id = item.id;
      obj.class = "뉴스";
      obj.title = item.title;
      obj.contents = item.contents;
      obj.imgSrc = [];
      for (let attc of item.attachments) {
        obj.imgSrc.push(attc.download_url);
      }
      obj.created_dttm = item.created_dttm.split("T")[0];
      result.push(obj);
    }

    //날짜순으로 정렬
    let sorted;
    sorted = result.sort(function (a, b) {
      if (b.created_dttm > a.created_dttm) return 1;
      if (b.created_dttm < a.created_dttm) return -1;
      return 0;
    });

    //정렬된 리스트에 인덱스 부여
    for (let i = 0; i < sorted.length; i++) {
      sorted[i]["index"] = sorted.length - i;
    }

    state.boardData = sorted;
  },

  SEARCH_NEWS_BOARD: function (state, payload) {
    let boardData = payload[0];
    let searchText = payload[1];

    let result;
    result = boardData.filter((item) => {
      let filtered = true;
      filtered =
        item.title.includes(searchText) || item.contents.includes(searchText);
      return filtered;
    });

    state.searchedData = result;
  },

  SET_EVENT: function (state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};

      obj.id = item.id;
      obj.type = item.type;
      obj.title = item.title;
      obj.url = item.url;
      obj.start = item.start_day;
      obj.end = item.end_day;

      if (item.attachment != null) {
        obj.imgSrc = item.attachment.download_url;
      }

      result.push(obj);
    }

    state.eventData = result;
  },

  SET_MAGAZINE: function (state, resData) {
    let result = [];
    let cnt = 1;
    for (let item of resData) {
      let obj = {};
      
      obj.id = item.id;
      obj.title = item.title;
      obj.contents = item.contents;
      obj.created_dttm = item.created_dttm.substring(0,10);
      obj.imgSrc = [];
      obj.under_title = item.under_title;
      obj.cnt = cnt;
      obj.type = [];

      for (let attc of item.attachments) {
        obj.type.push(attc.type);
        obj.imgSrc.push(attc.download_url);
      }

      result.push(obj);
      cnt++;
    }

    state.magazineData = result;
  },
};
